.login-page {
    width: 100%;
    padding: 8% 0 0;
    margin: auto;
  }
  
  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  fieldset {
    border-color: #e2e8f0 !important;
    color: #e2e8f0 !important;
  }
  
  input::-webkit-contacts-auto-fill-button,
  input::-webkit-credentials-auto-fill-button {
    color:  #e2e8f0 !important;
    background-color:  #e2e8f0 !important;
  }
  
  @keyframes slideLeft {
    from {transform: translateX(0);}
    to {transform: translateX(-100vw)}
  }
  
  @keyframes slideRight {
    from {transform: translateX(0);}
    to {transform: translateX(100vw)}
  }
  
  .blobAnimation {
    animation-name: slideLeft;
    animation-duration: 3s;
    animation-delay: 150ms;
    -webkit-animation-fill-mode: forwards; /* Chrome 16+, Safari 4+ */
    -moz-animation-fill-mode: forwards;    /* FF 5+ */
    -o-animation-fill-mode: forwards;      /* Not implemented yet */
    -ms-animation-fill-mode: forwards;     /* IE 10+ */
    animation-fill-mode: forwards;         /* When the spec is finished */
  }
  
  .formAnimation {
    animation-name: slideRight;
    animation-duration: 3s;
    animation-delay: 150ms;
    -webkit-animation-fill-mode: forwards; /* Chrome 16+, Safari 4+ */
    -moz-animation-fill-mode: forwards;    /* FF 5+ */
    -o-animation-fill-mode: forwards;      /* Not implemented yet */
    -ms-animation-fill-mode: forwards;     /* IE 10+ */
    animation-fill-mode: forwards;         /* When the spec is finished */
  }
  
  @keyframes rotateLock {
    from {transform: rotate(0)}
    to {transform: rotate(-360deg)}
  }
  .lockAnimation{
    animation-name: rotateLock;
    animation-duration: 1s;
    animation-delay: 150ms;
  }