* {
    -ms-overflow-style: none;
}

::-webkit-scrollbar {
    display: none;
}

.custom-checkbox-root .MuiSvgIcon-root {
    width: 36px;
    height: 36px;
}